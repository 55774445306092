import { Head } from '@inertiajs/react';
import { PageProps } from '@/types';
import background from '../../assets/images/man-running-sunrise.webp';
import React from 'react';
import PublicLayout from '@/Layouts/PublicLayout';
export default function Index({}: PageProps<{ laravelVersion: string; phpVersion: string }>) {
  return (
    <PublicLayout
      header={
        <h2 className="font-semibold text-xl text-gray-800 dark:text-gray-200 leading-tight">
          Dashboard
        </h2>
      }
    >
      <Head>
        <title>Home</title>
        <meta name="description" content="Runlife - Movidos a Superação" />
        <meta property="og:title" content="Runlife - Movidos a Superação" />
        <meta property="og:url" content="https://runlife.com.br" />
        <meta property="og:description" content="Runlife - Movidos a Superação" />
        <meta property="og:image" content="https://runlife.com.br/images/runlifeapp-small.png" />
        <link rel="icon" type="image/svg+xml" href="/favicon.ico" />
      </Head>
      <div className="bg-gray-50 text-black/50 dark:bg-black dark:text-white/50">
        <img id="background" className="absolute" src={background} />
        <div className="relative min-h-screen flex flex-col items-center justify-center selection:text-white">
          <div className="relative w-full max-w-2xl lg:max-w-7xl">
            <main className="mt-6">
              <div className="grid gap-6 lg:grid-cols-2 lg:gap-8" style={{ width: '500px' }}></div>
              <div className="max-w-6xl mx-auto px-4 py-12">
                <div className="bg-gray-100 dark:bg-gray-900  rounded-lg shadow-md">
                  <h1 className="text-3xl font-bold text-center mb-8 text-gray-600 dark:text-gray-400">
                    Descubra as Funcionalidades do Nosso Sistema
                  </h1>
                </div>
                <div className="grid gap-6 md:grid-cols-3">
                  <div className="bg-gray-100 dark:bg-gray-900 rounded-lg shadow-md p-6 hover:shadow-xl transition-shadow duration-300">
                    <div className="flex items-center mb-4">
                      <svg
                        className="w-8 h-8 text-gray-600 dark:text-gray-400 mr-3"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                      >
                        <path d="M4 4h16v2H4V4zm0 4h16v2H4V8zm0 4h10v2H4v-2zm0 4h10v2H4v-2zm12 0v2h2v-2h-2zm0-4v2h4v-2h-4zm0-4v2h6V8h-6zm0-4v2h8V4h-8z" />
                      </svg>
                      <h2 className="text-xl font-semibold text-gray-600 dark:text-gray-400 mb-4">
                        Gestão Completa de Eventos de Corrida
                      </h2>
                    </div>
                    <p className="text-gray-600 dark:text-gray-400">
                      Crie e gerencie seus eventos de corrida com total controle e praticidade.
                      Nosso sistema oferece ferramentas para cadastro de corridas, gerenciamento de
                      inscrições, e acompanhamento de todos os detalhes importantes em um único
                      lugar. Transforme a forma como você organiza corridas, com eficiência e
                      agilidade.
                    </p>
                  </div>
                  <div className="bg-gray-100 dark:bg-gray-900 rounded-lg shadow-md p-6 hover:shadow-xl transition-shadow duration-300">
                    <div className="flex items-center mb-4">
                      <svg
                        className="w-8 h-8 text-gray-600 dark:text-gray-400 mr-3"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M3 7V3h18v4M5 21v-6M19 21v-6M9 21v-8a2 2 0 014 0v8"
                        />
                      </svg>
                      <h2 className="text-xl font-semibold text-gray-600 dark:text-gray-400 mb-4">
                        Inscrições Simplificadas para Atletas
                      </h2>
                    </div>
                    <p className="text-gray-600 dark:text-gray-400">
                      Ofereça uma experiência de inscrição sem complicações para os atletas. Com
                      nossa plataforma, os participantes podem se inscrever rapidamente, escolher as
                      modalidades desejadas e realizar pagamentos de forma segura. Tudo isso em um
                      ambiente intuitivo e fácil de usar, que valoriza o tempo dos seus atletas.
                    </p>
                  </div>
                  <div className="bg-gray-100 dark:bg-gray-900 rounded-lg shadow-md p-6 hover:shadow-xl transition-shadow duration-300">
                    <div className="flex items-center mb-4">
                      <svg
                        className="w-8 h-8 text-gray-600 dark:text-gray-400 mr-3"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M9 12h6M9 16h6M9 8h6m-3-4h3a2 2 0 012 2v16a2 2 0 01-2 2H6a2 2 0 01-2-2V6a2 2 0 012-2h3M7 16h.01M7 12h.01M7 8h.01"
                        />
                      </svg>
                      <h2 className="text-xl font-semibold text-gray-600 dark:text-gray-400 mb-4">
                        Dashboard Inteligente para Monitoramento
                      </h2>
                    </div>
                    <p className="text-gray-600 dark:text-gray-400">
                      Acompanhe o progresso do seu evento em tempo real com nosso dashboard
                      inteligente. Tenha acesso a dados importantes, como número de inscritos,
                      status de pagamentos e relatórios detalhados. Com visualizações claras e
                      funcionalidades personalizáveis, você toma decisões mais rápidas e assertivas
                      para o sucesso da sua corrida.
                    </p>
                  </div>
                </div>
              </div>
            </main>

            <footer className="py-8 text-center text-sm text-black dark:text-white bg-gray-100 dark:bg-gray-900 ">
              runlife.com.br
            </footer>
          </div>
        </div>
      </div>
    </PublicLayout>
  );
}
